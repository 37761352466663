import { Controller } from 'stimulus'

export default class extends Controller {
  open () {
    const url = prompt('Where to?', 'http://max.local')
    if (url !== null) {
      window.location.href = url
    }
  }
}
