import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'digit', 'submit']

  digit ({ currentTarget }) {
    const d = currentTarget.innerText.trim()

    if (d === ',' && this.inputTarget.value.includes(',')) {
      return
    }

    if (this.inputTarget.value.slice(0) === '0') {
      this.inputTarget.value = d
    } else {
      this.inputTarget.value = `${this.inputTarget.value}${d}`
    }
  }

  delete () {
    let newValue = this.inputTarget.value.slice(0, -1)
    if (newValue === '') {
      newValue = '0'
    }

    this.inputTarget.value = newValue
  }
}
