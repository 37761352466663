import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "template", "container" ]

  append () {
    const el = document.importNode(this.templateTarget.content, true)
    this.containerTarget.appendChild(el)
  }

  appendBeforeLast () {
    const el = document.importNode(this.templateTarget.content, true)
    this.containerTarget.insertBefore(el, this.containerTarget.lastElementChild)
  }
}
