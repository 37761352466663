import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selectAll", "item"];

  connect() {
    this.checked = [];
    this.values = {};

    this.itemTargets.forEach((item, idx) => {
      this.checked[idx] = item.checked;
      this.values[idx] = item.value;
      item.dataset.idx = idx;
    });

    this.setSelectAll();
  }

  toggle({ currentTarget }) {
    this.checked[currentTarget.dataset.idx] = currentTarget.checked;
    if (currentTarget.checked) {
      this.values[currentTarget.dataset.idx] = currentTarget.value;
    } else {
      delete this.values[currentTarget.dataset.idx];
    }

    this.setSelectAll();
    console.log(this.values);
  }

  checkAll(checked = true) {
    this.itemTargets.forEach((item, idx) => {
      this.checked[idx] = checked;
      item.checked = checked;

      if (checked) {
        this.values[idx] = item.value;
      } else {
        this.values = {};
      }
    });

    console.log();
  }

  selectAll({ currentTarget }) {
    if (currentTarget.checked) {
      this.checkAll();
      return;
    }

    if (!currentTarget.checked) {
      this.checkAll(false);
      return;
    }

    // if (currentTarget.indeterminate) {
    //   console.log(indeterminate);
    // }

    // if (currentTarget.checked) {
    // }
  }

  setSelectAll() {
    console.log(this.checked.filter(Boolean).length);
    // console.log(this.checked.filter(Boolean).length === 0);

    if (this.checked.length === this.checked.filter(Boolean).length) {
      this.selectAllTarget.indeterminate = false;
      this.selectAllTarget.checked = true;
    } else if (this.checked.filter(Boolean).length === 0) {
      this.selectAllTarget.indeterminate = false;
      this.selectAllTarget.checked = false;
    } else {
      this.selectAllTarget.indeterminate = true;
    }
  }

  // update() {
  //   let count = this.inputTarget.value.trim().length;
  //   if (count <= 0) {
  //     this.clear();
  //     return;
  //   }

  //   if (this.inputTarget.getAttribute("maxlength") !== null) {
  //     count += `/${this.inputTarget.getAttribute("maxlength")}`;
  //   }

  //   this.countTarget.innerText = count;
  // }

  // clear() {
  //   this.countTarget.innerText = "";
  // }
}
