import { Controller } from 'stimulus'

// click_controller clicks things
export default class extends Controller {
  static targets = ["clickable"]

  click () {
    this.clickableTargets.forEach(el => el.click())
  }
}
