import { Controller } from 'stimulus';
import CodeMirror from 'codemirror/lib/codemirror'
import 'codemirror/mode/markdown/markdown'
import 'codemirror/mode/yaml/yaml'
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/addon/edit/trailingspace'

export default class extends Controller {
  static values = { format: String }
  static targets = [ "textarea", "wordcount" ]

  connect () {
    // support old version where the controller connects to the textarea itelf
    // as well as the new one where the textarea is an explicit target
    if (this.hasTextareaTarget) {
      this.textarea = this.textareaTarget
    } else {
      this.textarea = this.element
    }

    switch(this.formatValue) {
      case "markdown":
        this.markdown()
        break;
      case "yaml":
        this.yaml()
        break;
      default:
        throw new Error('format needs to be one of: markdown, yaml')
    }
  }

  // sets up a Markdown editor
  markdown () {
    this.editor = CodeMirror.fromTextArea(this.textarea, {
      mode: 'text/x-markdown',
      lineWrapping: true,
      viewportMargin: Infinity,
      tabSize: 2,
      lineNumbers: true,
      autoCloseBrackets: true,
      showTrailingSpace: true,
    })

    this.editor.addKeyMap({
      // typical "link selection" shortcut cmd+k
      "Cmd-K": function(cm){
        cm.replaceSelection(`[${cm.getSelection()}]()`)

        // move cursor one step back to place it in the () brackets (where the link target goes)
        var cursor = cm.getCursor()
        cursor.ch = cursor.ch - 1
        cm.setCursor(cursor)
      },

      // bold
      "Cmd-B": function(cm){
        cm.replaceSelection(`**${cm.getSelection()}**`)

        var cursor = cm.getCursor()
        cursor.ch = cursor.ch - 2
        cm.setCursor(cursor)
      },

      // italic
      "Cmd-I": function(cm){
        cm.replaceSelection(`_${cm.getSelection()}_`)

        var cursor = cm.getCursor()
        cursor.ch = cursor.ch - 1
        cm.setCursor(cursor)
      },

      // Add escape backslash (\) for easy escaping
      "Cmd-E": function(cm){
        cm.replaceSelection(`\\${cm.getSelection()}`)
      },
    })

    this.editor.on("change", () => { this.updateWordCount() })
    this.updateWordCount()
  }

  // sets up a YAML editor
  yaml () {
    this.editor = CodeMirror.fromTextArea(this.textarea, {
      mode: 'text/x-yaml',
      lineWrapping: true,
      viewportMargin: Infinity,
      tabSize: 2,
      lineNumbers: true,
      autoCloseBrackets: true,
    })

    // indent with spaces as YAML is picky about that
    this.editor.setOption("extraKeys", {
      Tab: function(cm) {
        var spaces = Array(cm.getOption("indentUnit") + 1).join(" ")
        cm.replaceSelection(spaces)
      }
    })
  }

  // teardown is called during turbo:before-cache which is bound through stimulus global binding
  teardown () {
    this.editor.toTextArea()
  }

  updateWordCount() {
    if (!this.hasWordcountTarget) return
    this.wordcountTarget.innerText = `${this.countWords(this.editor.getValue())} words`
  }

  countWords (text) {
    return text.length && text.split(/\s+\b/).length || 0
  }
}
